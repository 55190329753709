import React from 'react';
import { Box, Heading, Button, Text } from '@chakra-ui/react';

import { BG_IG_FILTER } from '@constants/assets';
import {
  HEADING_STYLES,
  DEFAULT_BUTTON_PROPS,
  TEXT_DECORATION,
  TEXT_PRIMARY,
} from '@constants/colors';
import { ID_INSTAGRAM_SECTION } from '@constants/identifier';
import { IG_FILTER } from '@constants/';

import txt from './locales';
import useLang from '@hooks/useLang';

function InstagramFilter() {
  const lang = useLang();

  const handleClickFilterIG = () => {
    window.open(IG_FILTER, '_blank');
  };

  return (
    <Box bgColor="bgPrimary" name={ID_INSTAGRAM_SECTION}>
      <Box
        width="100%"
        height="280px"
        bgImage={`url(${BG_IG_FILTER})`}
        bgSize="cover"
        bgPosition="center"
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Heading {...HEADING_STYLES} fontSize={'28px'} marginBottom={'16px'} color="black">
          Instagram Filter
        </Heading>
        <Text fontSize={'16px'} align="center" padding="0 2rem 0 2rem" marginBottom={'8px'} color="black">
          Capture your moment on our special day by using the Instagram filter below:
        </Text>
        <Button
          {...DEFAULT_BUTTON_PROPS}
          onClick={handleClickFilterIG}
          fontSize={'18px'}
          padding="16px 30px"
          _hover={{
            backgroundColor: TEXT_DECORATION,
            borderColor: TEXT_PRIMARY,
            transform: 'none',
          }}
        >
          {txt.textButton[lang]}
        </Button>
      </Box>
    </Box>
  );
}

export default InstagramFilter;
