import React from 'react';

import { number } from 'prop-types';

import { FiCheck } from 'react-icons/fi';

function ProgressStepper({ currentStep, totalSteps }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      {[...Array(totalSteps)].map((_, index) => {
        let backgroundColor = '#cdc1b1';
        if (index < currentStep) backgroundColor = '#cdc1b1';
        if (index === currentStep) backgroundColor = '#bf9876';

        return (
          <React.Fragment key={index}>
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor,
                zIndex: 1,
                display: 'grid',
                placeContent: 'center',
                color: 'white',
                fontSize: '23px',
                paddingTop: '2px',
              }}
            >
              {index === currentStep ? <FiCheck /> : index + 1}
            </div>
            {index !== totalSteps - 1 && ( // Don't render line after the last step
              <div
                style={{
                  width: 'calc(60% / 3)',
                  borderTop: '2px dashed #cdc1b1',
                  zIndex: 0,
                  marginRight: '10px',
                  marginLeft: '10px',
                }}
              ></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

ProgressStepper.propTypes = {
  currentStep: number.isRequired,
  totalSteps: number.isRequired,
};

export default ProgressStepper;
