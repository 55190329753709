import get from '@invitato/helpers/dist/getLocale';

export default {
  menuNotFound: get('Menu tidak ditemukan!', `Section can't be found!`),
  accessCard: get('Kartu Akses', 'Access Card'),
  bride: get('Mempelai', 'New Doctor'),
  covidProtocol: get('Protokol Kesehatan', 'Health Protocol'),
  rsvp: get('RSVP', 'RSVP'),
  gift: get('Tanda Kasih', 'Send Gift'),
  details: get('Detail Acara', 'Event Details'),
  live: get('Siaran Langsung', 'Live Streaming'),
};
