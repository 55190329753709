import React from 'react';
import { Box, Button, Link, Heading, Text } from '@chakra-ui/react';

import { YOUTUBE_LINK } from '@/constants';

import txt from './locales';
import useLang from '@hooks/useLang';
import {
  HEADING_STYLES,
  DEFAULT_BUTTON_PROPS,
  // TEXT_DECORATION,
  TEXT_PRIMARY,
  ALTERNATE_BUTTON_COLOR,
} from '@constants/colors';
import { ID_YOUTUBE_SECTION } from '@constants/identifier';
import { BG_LIVESTREAM } from '@constants/assets';

function YoutubeLiveSection() {
  const defaultLang = useLang();
  const LANG = defaultLang;

  return (
    <Box name={ID_YOUTUBE_SECTION}>
      <Box
        width="100%"
        height="280px"
        bgImage={`url(${BG_LIVESTREAM})`}
        bgSize="cover"
        bgPosition="center"
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Heading {...HEADING_STYLES} fontSize={'28px'} marginBottom={'16px'} color="secondaryColorText">
          {txt.title[LANG]}
        </Heading>
        <Text fontSize={'16px'} align="center" padding="0 2rem 0 2rem" marginBottom={'8px'} color="white">
          To those who are unable to attend the venue, you can celebrate with us via Youtube livestream.
        </Text>
        <Link href={YOUTUBE_LINK} target="_blank">
          <Button
            {...DEFAULT_BUTTON_PROPS}
            fontSize={'18px'}
            padding="16px 30px"
            bgColor={ALTERNATE_BUTTON_COLOR}
            color="black"
            _hover={{
              backgroundColor: 'white',
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            {txt.open[LANG]}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
