import get from '@invitato/helpers/dist/getLocale';

export default {
  ourSincere: get('KAMI YANG BERBAHAGIA,', 'Visit Our Instagram'),
  ayatName: get('Matius 19:6', '1 Corinthians 13:1'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.`,
    `Love bears all things, believes all things, hopes all things, endures all things. Love never fails.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  thanks: get(
    'TERIMAKASIH UNTUK SEMUA KATA, <br />DOA, DAN PERHATIAN YANG DIBERIKAN.',
    'THANK YOU FOR ALL THE WORDS, <br />PRAYERS, AND ATTENTION GIVEN',
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are able to attend the special moment of our life. Words can not express how grateful we are for all the support and prayers given. See you!',
  ),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are able to attend the special moment of our life. Words can not express how grateful we are for all the support and prayers given. See you!',
  ),
};
