import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';

import {
  ENABLE_BANK_TRANSFER,
  ENABLE_SEND_GIFT,
  ENABLE_GIFT_CONFIRMATION,
} from '@constants/feature-flags';

import Bank from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';
import {
  HEADING_STYLES,
  TEXT_DECORATION,
  TEXT_PRIMARY,
  BG_SECONDARY,
  DEFAULT_BUTTON_PROPS,
} from '@constants/colors';

import Confirmation from '../Confirmation/Lazy';

function ModalGiftCard({ onClose, visible, onCopyText, lang }) {
  const name = 'Tifanny & Jared';
  const address = `Jalan Road 700, Kelurahan, Kecamatan, Kota.`;

  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="bgAlternative" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            colorScheme="blackAlpha"
            marginTop="16px"
            {...DEFAULT_BUTTON_PROPS}
            color="mainColorText"
            fontSize="sm"
            padding={'10px'}
            onClick={() => onCopyText(bankNumber)}
            _hover={{
              backgroundColor: TEXT_DECORATION,
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerBody padding={'2rem 0rem'}>
          <Text
            textAlign={'center'}
            fontSize={'lg'}
            paddingBottom={'1.5rem'}
            dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }}
          >
            {/* For beloved ones who may want to show <br></br> your sincere love by sending gift,
            please <br></br> kindly tap the button down below: */}
            {/* {txtWording.desc[lang]} */}
          </Text>
          {ENABLE_BANK_TRANSFER && (
            <>
              {/* Bank Section */}
              <Box
                bgColor={BG_SECONDARY}
                borderTop={'1px solid black'}
                borderBottom={'1px solid black'}
              >
                <Heading {...HEADING_STYLES} fontSize={'2xl'} padding={'1rem 2rem'}>
                  Bank Transfer
                </Heading>
              </Box>

              <Box marginTop={'10px'}>{renderBank(Bank.bri, 'Tifanny Jared', '01234567')}</Box>
            </>
          )}

          {/* Gift Section */}
          {ENABLE_SEND_GIFT && (
            <Box marginTop={'16px'} marginBottom={'24px'}>
              <Box
                bgColor={BG_SECONDARY}
                borderTop={'1px solid black'}
                borderBottom={'1px solid black'}
              >
                <Heading {...HEADING_STYLES} fontSize={'2xl'} padding={'1rem 2rem'}>
                  {txtWording.sendGift[lang]}
                </Heading>
              </Box>

              <Box padding="16px 0" marginTop={'10px'}>
                <Text textAlign="center" color="bgAlternative" marginTop="0" fontWeight="bold">
                  {name} <br />
                </Text>
                <Text textAlign="center" fontFamily="body" color="black" fontSize="md">
                  {address}
                </Text>
                <Center>
                  <Button
                    colorScheme="blackAlpha"
                    marginTop="16px"
                    {...DEFAULT_BUTTON_PROPS}
                    color="mainColorText"
                    fontSize="sm"
                    padding={'10px'}
                    onClick={() =>
                      onCopyText(`${name} - ${address}`, txtWording.successCopyAddress[lang])
                    }
                    _hover={{
                      backgroundColor: TEXT_DECORATION,
                      borderColor: TEXT_PRIMARY,
                      transform: 'none',
                    }}
                  >
                    {txtWording.modalCopy[lang]}
                  </Button>
                </Center>
              </Box>
            </Box>
          )}

          {ENABLE_GIFT_CONFIRMATION && (
            <>
              <Confirmation lang={lang} />
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalGiftCard.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalGiftCard.defaultProps = {
  lang: 'id',
};

export default ModalGiftCard;
