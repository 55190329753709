import P1 from '@/images/compassion/1.jpg';
import P2 from '@/images/compassion/2.jpg';
import P3 from '@/images/compassion/3.jpg';
import P4 from '@/images/compassion/4.jpg';
import P5 from '@/images/compassion/5.jpg';
// import P6 from '@/images/compassion/6.jpg';
// import P7 from '@/images/compassion/7.jpg';
// import P8 from '@/images/compassion/8.jpg';
// import P9 from '@/images/compassion/9.jpg';
// import P10 from '@/images/compassion/10.jpg';
// import P11 from '@/images/compassion/11.jpg';
// import P12 from '@/images/compassion/12.jpg';
// import P13 from '@/images/compassion/13.jpg';
// import P14 from '@/images/compassion/14.jpg';
// import P15 from '@/images/compassion/15.jpg';
// import P16 from '@/images/compassion/16.jpg';
// import P17 from '@/images/compassion/17.jpg';
// import P18 from '@/images/compassion/18.jpg';
// import P19 from '@/images/compassion/19.jpg';
// import P20 from '@/images/compassion/20.jpg';
// import P21 from '@/images/compassion/21.jpg';
// import P22 from '@/images/compassion/22.jpg';
// import P23 from '@/images/compassion/23.jpg';
// import P24 from '@/images/compassion/24.jpg';

export const photos = [
  P1,
  P2,
  P3,
  P4,
  P5,
  // P6,
  // P7,
  // P8,
  // P9,
  // P10,
  // P11,
  // P12,
  // P13,
  // P14,
  // P15,
  // P16,
  // P17,
  // P18,
  // P19,
  // P20,
  // P21,
  // P22,
  // P23,
  // P24,
];
