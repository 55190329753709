import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak-Ibu-Saudara/i,', 'Dear Doctor Mr/Mrs/Ms,'),
  guest: get('Keluarga & Teman-teman', 'Family & Friends'),
  invitationMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami.`,
    `With the blessing of Allah SWT, we cordially request the honor of your presence at our wedding.`,
  ),
  announcementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami berniat ingin mengumumkan acara pernikahan kami kepada Bapak/Ibu/Saudara/i.`,
    `With the blessing of Allah SWT, we joyfully announce the upcoming of our marriage.`,
  ),

  welcomingInvitationGeneral: get(
    `Dengan segala kerendahan hati, kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami`,
    'With honor we are inviting you to <br />our Hippocratic Oath Batch I 2024 <br />School of Medicine and Health Sciences <br />Atma Jaya Catholic University of Indonesia.',
  ),

  welcomingAnnouncementGeneral: get(
    'Dengan segala kerendahan hati, kami berniat ingin mengumumkan acara pernikahan kami kepada Bapak/Ibu/Saudara/i.',
    'Together with joyful hearts and the grace of God, we joyfully announce the upcoming of our marriage.',
  ),
};
