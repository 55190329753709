import React, { useState } from 'react';
import { string } from 'prop-types';

import { Box, Heading, Button, useToast, Link, Text } from '@chakra-ui/react';

import ModalGiftCard from './ModalGiftCard';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';

import txtWording from './locales';

import { DEFAULT_LANGUAGE } from '@/constants/feature-flags';
import {
  DEFAULT_BUTTON_PROPS,
  HEADING_STYLES,
  TEXT_DECORATION,
  TEXT_PRIMARY,
} from '@/constants/colors';
import { BG_GIFTCARD } from '@constants/assets';
import { ID_GIFT_SECTION } from '@constants/identifier';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  open: 'open',
  confirm: 'confirmation',
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ lang }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Ooops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box bgColor="bgPrimary" name={ID_GIFT_SECTION}>
      <Box
        width="100%"
        height="280px"
        bgImage={`url(${BG_GIFTCARD})`}
        bgSize="cover"
        bgPosition="center"
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Heading {...HEADING_STYLES} fontSize={'28px'} marginBottom={'20px'} color="secondaryColorText">
          {txtWording.title[lang]}
        </Heading>
        <Link href="https://drive.google.com/file/d/19ffMygW4OJniwrZG-O9kNLl-3kzRCRhU/view?usp=sharing" target="_blank">
          <Button
            {...DEFAULT_BUTTON_PROPS}
            fontSize={'18px'}
            padding="16px 30px"
            _hover={{
              backgroundColor: TEXT_DECORATION,
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            Catalogue
          </Button>
        </Link>
        <Text fontSize={'16px'} align="center" padding="0 2rem 0 2rem" marginTop="8px" color="white">
          To the beloved ones who would like to show your sincere love 
          and support by sending a gift, kindly tap the button bellow.
        </Text>
        <Link href="https://forms.gle/B7HEK6HTvYvdWtQH6" target="_blank">
          <Button
            {...DEFAULT_BUTTON_PROPS}
            fontSize={'18px'}
            marginTop="18px"
            padding="16px 30px"
            _hover={{
              backgroundColor: TEXT_DECORATION,
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            Order Here
          </Button>
        </Link>
        <Text fontSize={'16px'} align="center" padding="0 2rem 0 2rem" marginTop="8px" color="white">
          Last Order: January 17<sup>th</sup>, 2024 (contact CP before ordering for confirmation)
        </Text>
        {false && (
          <Button
            {...DEFAULT_BUTTON_PROPS}
            onClick={() => setModalState(MODAL_STATE.open)}
            fontSize={'18px'}
            padding="16px 30px"
            _hover={{
              backgroundColor: TEXT_DECORATION,
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            {txtWording.button[lang]}
          </Button>
        )}
      </Box>

      {false && (
        <ModalGiftCard
          lang={lang}
          onClose={onClose}
          visible={modalState === MODAL_STATE.open}
          onCopyText={onCopyText}
        />
      )}
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: DEFAULT_LANGUAGE,
};

export default React.memo(GiftCardSection);
