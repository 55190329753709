import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import LineDecoration from '@components/Common/LineDecoration';
import DetailCard from './DetailCard';

import useRsvp from '@/usecase/use-rsvp';
import useCobaLink from '@/hooks/useCobaLink';

import { IMG_MAN, IMG_GIRL, IMG_BOY } from '@/constants/assets';
import { ID_COUPLE_SECTION } from '@constants/identifier';
import { HEADING_ALTERNATIVE_STYLES } from '@constants/colors';

function CoupleInfo() {
  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  return (
    <Box
      name={ID_COUPLE_SECTION}
      bgColor="bgPrimary"
      padding={'1rem 0rem 2rem 0rem'}
      textAlign={'center'}
      overflow={'hidden'}
    >
      <WithAnimation>
        <Box {...HEADING_ALTERNATIVE_STYLES}>
          <Text fontSize="md">Introducing 57 New Doctors</Text>
        </Box>
      </WithAnimation>

      <Box
        position="relative"
        sx={{
          width: '280px',
          height: '280px',

          '@media screen and (max-width: 400px)': {
            width: '255px',
            height: '255px',
          },
        }}
        bgImage={`url(${IMG_MAN})`}
        bgSize="cover"
        bgPosition="center"
        margin={'40px auto 32px auto'}
      >
        <Box
          position="absolute"
          sx={{
            top: '-36px',
            left: '-180px',
            '@media screen and (max-width: 400px)': {
              top: '-60px',
              left: '-145px',
            },
          }}
        >
          <LineDecoration width="270px" height="330px" />
        </Box>
      </Box>

      <DetailCard
        fullName="dr. Abigail Tania<br /> dr. Adrian Djatikusumo<br /> dr. Alexander Yose Ardin Bagas Sulistyo<br /> 
          dr. Amanda Risviandari<br /> dr. Amelia<br /> dr. Anastasia Stephanie Nurtanio<br /> 
          dr. Angeline Praditama Ismail<br /> dr. Anindita Agung Pradnya Savitri<br /> dr. Annisa Dewi Pralifna Hernanto<br />
          dr. Antonius Andrew Hadinata<br /> dr. Aristo Constantine<br /> dr. Belinda Susanto<br /> 
          dr. Catherine Patricia Soetedjo<br /> dr. Christian Joseph Tanuwidjaja<br /> dr. Cindy Claudya<br /> 
          dr. Cindy Tan<br /> dr. Clarissa Edelyne Kho<br /> dr. Dennis Wijaya Sianto<br /> dr. Destian Monica
        "
      />

      <Box
        position="relative"
        sx={{
          width: '280px',
          height: '280px',

          '@media screen and (max-width: 400px)': {
            width: '255px',
            height: '255px',
          },
        }}
        bgImage={`url(${IMG_GIRL})`}
        bgSize="cover"
        bgPosition="center"
        margin={'40px auto 32px auto'}
      >
        <Box
          position="absolute"
          sx={{
            top: '-36px',
            left: '-180px',
            '@media screen and (max-width: 400px)': {
              top: '-60px',
              left: '-145px',
            },
          }}
        >
          {false && <LineDecoration width="270px" height="330px" />}
        </Box>
      </Box>

      <DetailCard
        fullName="dr. Dita Melinia<br /> dr. Ditalia Benezia<br /> dr. Edgina Liezandi<br /> dr. Felicia Jahja<br />
          dr. Ferdhito Christadi Gunawan<br /> dr. Gabriella Julia Stefanie<br /> dr. Gilang Samudero<br /> 
          dr. Gita Sirini Candijaya<br /> dr. Gloria Vicitta<br /> dr. Helen Susanto<br />
          dr. Inge Wijayanti Herman<br /> dr. Ira Irwanto<br /> dr. Jenifer Nathania<br /> 
          dr. Jennifer Ellen Tanjung<br /> dr. Jessica Rosemary Wikanto<br /> dr. Jonathan Naaman Ching<br /> 
          dr. Josephine Caesarlia Loanda Sihombing<br /> dr. Karenina Novani Sugondo<br /> dr. Kevin Jonathan Bogar          
        "
      />
      
      <Box
        position="relative"
        sx={{
          width: '280px',
          height: '280px',

          '@media screen and (max-width: 400px)': {
            width: '255px',
            height: '255px',
          },
        }}
        bgImage={`url(${IMG_BOY})`}
        bgSize="cover"
        bgPosition="center"
        margin={'40px auto 32px auto'}
      >
        <Box
          position="absolute"
          sx={{
            top: '-36px',
            left: '-180px',
            '@media screen and (max-width: 400px)': {
              top: '-60px',
              left: '-145px',
            },
          }}
        >
          {false && <LineDecoration width="270px" height="330px" />}
        </Box>
      </Box>

      <DetailCard
        fullName="dr. Kevin Revana Susanto<br /> dr. Kevin Tanoto<br /> dr. Kim Vanessa Phoane<br /> 
          dr. Lukas Arya Kusuma<br /> dr. Lydia Esterlita Halim<br /> dr. Margaretha Samantha Ulina Parhusip<br />
          dr. Maria Christina Sekarlangit<br /> dr. Mikhael Panji Nugroho<br /> dr. Patricia Angelika W.<br />
          dr. Petra Belinda<br /> dr. Priscilla Aurelia Tandry<br /> dr. Putri Maya Sari<br /> 
          dr. Regina Pebriana<br /> dr. Reyhan Janitra Gunawan<br /> dr. Sabrina Danica<br /> 
          dr. Teresa Dita<br /> dr. Theresia Radinda Wahyudi<br /> dr. Yocelyn<br /> dr. Yuriko Wada
        "
        // instagramId={IS_BOY_FIRST ? IG_GIRL : IG_BOY}
      />
    </Box>
  );
}

export default React.memo(CoupleInfo);